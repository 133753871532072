import * as React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import parse from 'html-react-parser'
import { parseStandardBullets } from '/src/functions/parse/standardBullets'
import { parseStyledCheckList } from '/src/functions/parse/styledCheckList'
import joinClasses from '/src/functions/joinClasses'
import './styles.scss'

const ButtonLink = loadable(() => import('/src/components/Basic/Buttons/ButtonLink'))
const ContentDisclaimer = loadable(() => import('/src/components/Basic/ContentDisclaimer'))
const CtaContainer = loadable(() => import('/src/components/Basic/CtaContainer'))
const Section = loadable(() => import('/src/components/Structure/Section/Section'))
const SectionHeader = loadable(() => import('/src/components/Structure/Section/SectionHeader'))

const Text = ({
    id,
    backgroundColour,
    blockSettings,
    titleArea,
    bulletStyle,
    content,
    addCta,
    callToAction,
    addSmallprint,
    smallprint,
    utm
}) => {
    const paddingSize = blockSettings?.paddingSize
    const blockClass = blockSettings?.blockClass

    const blockContent =
        bulletStyle === 'checklist'
            ? parse(content, parseStyledCheckList('light'))
            : parse(content, parseStandardBullets())

    const classes = ['c-text-block',blockClass]

    const concatenatedClasses = joinClasses(classes)

    return (
        <Section
            id={id}
            variant={backgroundColour}
            size={paddingSize}
            titleArea={titleArea}
            className={concatenatedClasses}
        >
            <div className="row">
                <div className="col-lg-10 offset-lg-1">
                    {titleArea.addTitleArea && titleArea.titlePosition === 'inline' && (
                        <SectionHeader variant={'inline'} titleArea={titleArea} />
                    )}

                    {blockContent}
                </div>
            </div>

            {addCta && callToAction && (
                <CtaContainer align={'centered'}>
                    <ButtonLink data={callToAction} size={'lg'} icon={'arrow'} utm={utm} />
                </CtaContainer>
            )}

            {addSmallprint && smallprint && (
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <ContentDisclaimer content={smallprint} />
                    </div>
                </div>
            )}
        </Section>
    )
}

Text.propTypes = {
    id: PropTypes.string,
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient']),
    titleArea: PropTypes.shape({
        addTitleArea: PropTypes.bool,
        titlePosition: PropTypes.oneOf(['inline', 'centered']),
        headingLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
        title: PropTypes.string,
        description: PropTypes.string
    }),
    bulletStyle: PropTypes.oneOf(['standard', 'checklist']),
    content: PropTypes.string.isRequired,
    addCta: PropTypes.bool,
    callToAction: PropTypes.shape({
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        target: PropTypes.string
    }),
    addSmallprint: PropTypes.bool,
    smallprint: PropTypes.string,
    /**
     * Optional page UTMs - e.g. `?utm_campaign=campaign_name&utm_source=source_name`
     */
    utm: PropTypes.string
}

Text.defaultProps = {
    backgroundColour: 'light',
    titleArea: {
        addTitleArea: false,
        titlePosition: 'centered',
        headingLevel: 'h2'
    },
    bulletStyle: 'standard',
    content: `
        <h2>Our mission, our mantra</h2>
        <p>We pride ourselves on our transparency and simplicity. You'll always find:</p>
        <ul>
            <li><strong>Honest &amp; impartial information:</strong> We're a non-advisory service, so we won't give our opinions on your cover. Instead, you'll always be given the facts in plain English so you can make the important decisions yourself;</li>
            <li><strong>A fee-free service:</strong> We don't charge a fee for our services. We're paid by the insurer, so you can use our service without worrying that you'll get stung by any fees or extra charges.</li>
            <li><strong>Friendly &amp; professional specialists:</strong> You'll have a dedicated specialist at every stage of your application who will do all the hard work for you;</li>
            <li><strong>A jargon-free and simple approach:</strong> Our life insurance experts are dedicated to making your application process as simple and stress-free as possible. We'll have a chat and really get to know you and put a smile on your face;</li>
            <li><strong>Options from many of the UK's leading insurers:</strong> We know that life insurance is not a one-size-fits-all product. That's why we offer protection products from a panel of the most trusted firms in the country to find your perfect fit;</li>
            <li><strong>Award-winning service:</strong> We have won 'Insurance Broker of the Year', 'Protection Broker of the Year' and 'Treating Customers Fairly Champion' in the Insurance Choice Awards, 2020. We've also won Sesame Ltd's prestigious 'Protection Firm of the Year' award twice, as well as AIG's Quality Awards for our work to improve our customer journey. We're also rated as the #1 life insurance brokerage on Trustpilot, determined entirely by our customers;</li>
            <li><strong>A conscientious ethic:</strong> We are an appointed representative of an FCA-approved regulator to ensure you're getting the most secure service, and we fundraise throughout the year to support charity work.</li>
        </ul>
        <p>We know that life is complicated enough already, so we'll never confuse you with jargon or pass you around the office. We'll never charge you for our help or bombard you with complicated forms to fill out. We've got you covered whichever protection product you choose.</p>
    `,
    addCta: false,
    callToAction: {
        url: '#',
        title: 'Button Title'
    },
    addSmallprint: false
}

export default Text

export const query = graphql`
    fragment TextForPageBuilder on WpPage_Acfpagebuilder_Layouts_Text {
        fieldGroupName
        blockSettings {
            paddingSize
            blockClass
        }
        backgroundColour
        addTitleArea
        title
        description
        bulletStyle
        content
        addCta
        callToAction {
            target
            title
            url
        }
    }
`
